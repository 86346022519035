import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {NavController} from '@ionic/angular';
import {Observable} from 'rxjs';
import {AppPaths} from '../../app-paths';
import {FirebaseService} from '../../../services/firebase/firebase.service';
import {NotificationCountService} from '../../../services/notification-count/notification-count.service';

@Component({
    selector: 'app-notification-count',
    templateUrl: './notification-count.component.html',
    styleUrls: ['./notification-count.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationCountComponent implements OnInit {

    unreadNotificationCount$: Observable<number>;

    constructor(private readonly navController: NavController,
                private readonly firebaseService: FirebaseService,
                private readonly notificationCountService: NotificationCountService) {
    }

    ngOnInit(): void {
        this.notificationCountService.updateNotificationCount();
        this.unreadNotificationCount$ = this.notificationCountService.unreadNotificationCountSubject;
    }

    ionViewWillEnter(): void {
        this.firebaseService.setScreenName('NotificationCount');
    }

    goToNotificationsPage(): void {
        this.firebaseService.logCustomEvent('tap_notifications_page');
        this.navController.navigateForward(AppPaths.NOTIFICATIONS_PAGE).then();
    }
}
