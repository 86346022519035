import {Injectable, NgZone} from '@angular/core';
import {forkJoin, noop, Observable, Observer, of} from 'rxjs';
import {NavController, Platform} from '@ionic/angular';
import {FirebaseX, MessagePayload} from '@awesome-cordova-plugins/firebase-x/ngx';
import {ToastService} from '../toast/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {catchError, tap, timeout} from 'rxjs/operators';
import {AppPaths} from '../../commons/app-paths';
import {SharedDataService} from '../shared-data/shared-data.service';
import {AppConstants} from '../../commons/app-constants';
import {OnPlatformEvents} from '../../utils/on-platform-events';
import {hasStringValues, isDefined, isNullOrUndefined} from '../../commons/utils';
import {environment} from '../../../environments/environment';
import {Idle} from '@ng-idle/core';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService extends OnPlatformEvents {
  private static remoteConfig = {
    [AppConstants.API_BASE_PATH]: environment.base.apiBasePath,
    [AppConstants.WEB_SOCKET_PATH]: environment.base.webSocketPath,
    [AppConstants.IAM_ISSUER_URL]: environment.base.iamIssuerURL,
    [AppConstants.APP_LINK]: environment.base.appLink,
    [AppConstants.EXCLUDE_GLOBAL_TENANT]: environment.base.excludeGlobalTenant,
    [AppConstants.AUTH_FLOWS]: environment.base.authFlows
  };

  constructor(ngZone: NgZone,
              private idle: Idle,
              private platform: Platform,
              private firebase: FirebaseX,
              private toast: ToastService,
              private translate: TranslateService,
              private sharedDataService: SharedDataService,
              private navCtrl: NavController) {
    super(platform, ngZone);
  }

  public static getRemoteConfig(): EnvironmentConfig {
    return this.remoteConfig;
  }

  public static getApiBasePath(): string {
    return this.remoteConfig[AppConstants.API_BASE_PATH];
  }

  public static getWebSocketPath(): string {
    return this.remoteConfig[AppConstants.WEB_SOCKET_PATH];
  }

  public static getIssuer(): string {
    return this.remoteConfig[AppConstants.IAM_ISSUER_URL];
  }

  public static getAppLink(): string {
    return this.remoteConfig[AppConstants.APP_LINK];
  }

  public static isExcludedGlobalTenant(): boolean {
    return hasStringValues(this.remoteConfig[AppConstants.EXCLUDE_GLOBAL_TENANT])
      ? JSON.parse(this.remoteConfig[AppConstants.EXCLUDE_GLOBAL_TENANT].toLowerCase())
      : false;
  }

  public static getAuthFlows(): string[] {
    return Array.from(this.remoteConfig[AppConstants.AUTH_FLOWS]
      .split(',')
      .map(elem => elem.trim()));
  }

  public static setEnvironmentType(type: EnvironmentConfig): void {
    FirebaseService.remoteConfig[AppConstants.API_BASE_PATH] = type.apiBasePath;
    FirebaseService.remoteConfig[AppConstants.WEB_SOCKET_PATH] = type.webSocketPath;
    FirebaseService.remoteConfig[AppConstants.IAM_ISSUER_URL] = type.iamIssuerURL;
    FirebaseService.remoteConfig[AppConstants.APP_LINK] = type.appLink;
    FirebaseService.remoteConfig[AppConstants.EXCLUDE_GLOBAL_TENANT] = type.excludeGlobalTenant;
    FirebaseService.remoteConfig[AppConstants.AUTH_FLOWS] = type.authFlows;
  }

  public getToken(): Observable<string> {
    return new Observable((result: Observer<string>) => {
      if (this.platform.is('android')) {
        this.getDeviceToken(result);
      }
      if (this.platform.is('ios')) {
        this.firebase.hasPermission().then(hasPermission => {
          if (hasPermission) {
            this.getDeviceToken(result);
          } else {
            this.grantPermissionAndGetDeviceToken(result);
          }
        });
      }
    });
  }

  private grantPermissionAndGetDeviceToken(result: Observer<string>): void {
    this.firebase.grantPermission()
      .then(() => this.getDeviceToken(result))
      .catch(() => {
        const firebaseErrorMessage = this.translate.instant(
          'error-message.firebase-token');
        this.toast.errorWithCancelButton(firebaseErrorMessage).then(toast =>
          toast.onDidDismiss().then(() => this.getDeviceToken(result)));
      });
  }

  private getDeviceToken(result: any): void {
    this.firebase.onTokenRefresh()
      .pipe(
        timeout(3000),
        catchError(() => of(null))
      )
      .subscribe(token => {
        result.next(token);
        result.complete();
      });
  }

  public listenToNotifications(): void {
    this.firebase.onMessageReceived().subscribe((message: MessagePayload) => {
      if (isDefined(message.tap)) {
        if (isNullOrUndefined(this.sharedDataService.getSharedData(AppConstants.ACCESS_TOKEN))) {
          this.sharedDataService.setSharedData(AppConstants.NEXT_ROUTE, AppPaths.NOTIFICATIONS_PAGE);
          this.navCtrl.navigateForward(AppPaths.LOGIN_PIN_CODE_PAGE).then();
        } else if (!this.idle.isIdling()) {
          this.navCtrl.navigateForward(AppPaths.NOTIFICATIONS_PAGE).then();
        } else {
          this.sharedDataService.setSharedData(AppConstants.NEXT_ROUTE, AppPaths.NOTIFICATIONS_PAGE);
        }
      }
    });
  }

  public setRemoteConfig(): Promise<void> {
    return new Promise(resolve => {
      // this.firebase.fetch().then(() => {
      //     this.firebase.activateFetched().then(() => this.setFirebaseEnvironment(resolve));
      // }, () => {
      //     FirebaseService.setEnvironmentType(environment.base);
      resolve();
      // });
    });
  }

  private setFirebaseEnvironment(resolve: (value?: (PromiseLike<void> | void)) => void): void {
    const apiPathPromise = this.firebase.getValue(AppConstants.API_BASE_PATH);
    const socketPathPromise = this.firebase.getValue(AppConstants.WEB_SOCKET_PATH);
    const iamIssuerPromise = this.firebase.getValue(AppConstants.IAM_ISSUER_URL);
    const appLinkPromise = this.firebase.getValue(AppConstants.APP_LINK);
    const globalTenantPromise = this.firebase.getValue(AppConstants.EXCLUDE_GLOBAL_TENANT);
    const authFlowsPromise = this.firebase.getValue(AppConstants.AUTH_FLOWS);
    forkJoin([
      apiPathPromise,
      socketPathPromise,
      iamIssuerPromise,
      appLinkPromise,
      globalTenantPromise,
      authFlowsPromise])
      .pipe(
        tap(([
          apiBasePath,
          webSocketPath,
          iamIssuerURL,
          appLink,
          excludeGlobalTenant,
          authFlows]) =>
          FirebaseService.setEnvironmentType({
            apiBasePath,
            webSocketPath,
            iamIssuerURL,
            appLink,
            excludeGlobalTenant,
            authFlows
          })
        )
      )
      .subscribe(
        () => resolve(),
        () => {
          FirebaseService.setEnvironmentType(environment.base);
          resolve();
        }
      );
  }

  ionPause(): void {
  }

  ionResume(): void {
    const nextRoute = this.sharedDataService.getSharedData(AppConstants.NEXT_ROUTE);
    isDefined(nextRoute) ? this.navCtrl.navigateRoot(AppPaths.LOGIN_PIN_CODE_PAGE) : noop();
  }

  logCustomEvent(type: string): void {
    this.firebase.logEvent(type, {}).then();
  }

  setScreenName(name: string): void {
    this.firebase.setScreenName(name).then();
  }
}

export class EnvironmentConfig {
  apiBasePath: string;
  webSocketPath: string;
  iamIssuerURL: string;
  appLink: string;
  excludeGlobalTenant: string;
  authFlows: string;
}
