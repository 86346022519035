import {Component, Input} from '@angular/core';
import {ApplicationSettings} from '../../../domain/contact-settings';

@Component({
    selector: 'app-qr-code-description',
    templateUrl: './qr-code-description.component.html',
    styleUrls: ['./qr-code-description.component.scss'],
})
export class QrCodeDescriptionComponent {

    @Input() applicationSettings: ApplicationSettings;
    constructor() {
    }
}
