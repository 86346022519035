export class ApplicationSettings {
    applicationName: string;
    copyrightYear: string;
    copyrightCompany: string;
}

export class BankContactSettings {
    showPhoneNumber: boolean;
    phoneNumber: string;
    showEmail: boolean;
    email: string;
    team: string;
}

export class ContactSettings {
    showSupportPhoneNumber: boolean;
    supportPhoneNumber: string;
    showSupportEmail: boolean;
    supportEmail: string;
    supportTeam: string;
    supportApplicationName: string;
    copyrightYear: string;
    copyrightCompany: string;
}
