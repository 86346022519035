import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, reduce} from 'rxjs';
import {HttpUtils} from '../../commons/http/http-utils';
import {FirebaseService} from '../firebase/firebase.service';
import {Product} from '../../domain/product';
import * as moment from 'moment/moment';
import {DateTimeUtilService} from '../date-time-util/date-time-util.service';
import {Moment} from 'moment';
import {map} from 'rxjs/operators';
import {InvalidBusinessDay} from '../../domain/invalid-business-day';

@Injectable({
    providedIn: 'root'
})
export class InvalidBusinessDayService {

    constructor(public http: HttpClient) {
    }

    getInvalidBusinessDays(currencies?: string[]): Observable<string[]> {
        const url = `${FirebaseService.getApiBasePath()}/api/global/invalid-business-days/all?currencies=${currencies}`;
        return this.http.get<InvalidBusinessDay[]>(url, HttpUtils.getRequestOptions()).pipe(
            map(ibd => ibd.map(ibd => ibd.date))
        );
    }

    getValidSpotDays(currencies: string[]): Observable<string[]> {
        const apiBasePath = FirebaseService.getApiBasePath();
        const url = `${apiBasePath}/api/global/invalid-business-days/valid-spot-days?currencies=${currencies}`;
        return this.http.get<string[]>(url, HttpUtils.getRequestOptions());
    }

    computeManualProduct(date: Moment, currencies: string[]): Observable<Product> {
        return this.getValidSpotDays(currencies).pipe(
            map(it => moment(it)),
            reduce(DateTimeUtilService.max('day'), moment()),
            map(maxSpotDay => date.isAfter(maxSpotDay) ? Product.FORWARD : Product.SPOT)
        );
    }
}
